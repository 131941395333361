import React from "react"

export default function VideoEmbed(props) {
  return (
    <div className="c-video-embed">
      <iframe
        src={props.url}
        id="vimeoplayer"
        frameborder="0"
        webkitAllowFullScreen
        mozallowfullscreen
        allowFullScreen
      ></iframe>
    </div>
  )
}
