import * as React from "react"
import { useState, useEffect } from "react"
import { Helmet } from "react-helmet"

import StudentHeader from "../../../../components/student-header"
import ImageHero from "../../../../components/image-hero"
import SectionHeader from "../../../../components/section-header"
import Footer from "../../../../components/footer"
import VideoEmbed from "../../../../components/video-embed"
import ButtonSign from "../../../../components/button-sign"
import ButtonPurple from "../../../../components/button-purple"
import ButtonOutline from "../../../../components/button-outline"
import QROverlay from "../../../../components/qr-overlay"

const img1 = require("../../../../images/utan-demokratiska-rattigheter/johan-dimitri-1.jpg")
const img2 = require("../../../../images/utan-demokratiska-rattigheter/johan-dimitri-2.jpg")
const img3 = require("../../../../images/utan-demokratiska-rattigheter/johan-dimitri-3.jpg")
const imgJohanDimitri = require("../../../../images/utan-demokratiska-rattigheter/johan-dimitri-portratt.png")

import "../../../../scss/site.scss"
//import StickyCta from "../../../../components/sticky-cta"

const JohanDimitriTaikon = props => {
  const [showOverlay, setShowOverlay] = useState(false)
  //const [caption, setCaption] = useState(null)
  //const [url, setUrl] = useState(null)
  const [heading, setHeading] = useState(null)
  const [text, setText] = useState(null)
  const [linkText, setLinkText] = useState(null)

  useEffect(() => {
    if (showOverlay) {
      document.body.style.overflowY = "hidden"
    } else {
      document.body.style.overflowY = ""
    }
    return () => {
      document.body.style.overflowY = ""
    }
  }, [showOverlay])

  /*const handleShowOverlay = (caption, url) => {
    if (caption) setCaption(caption)
    if (url) setUrl(url)
    setShowOverlay(!showOverlay)
  }*/

  const handleShowOverlay = (heading, text, linkText) => {
    if (heading) setHeading(heading)
    if (text) setText(text)
    if (linkText) setLinkText(linkText)
    setShowOverlay(!showOverlay)
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> Johan Dimitri Taikon | Demokrati Pågår </title>
        <link
          rel="canonical"
          href="https://www.demokratipagar.se/historiska-perspektiv/utan-demokratiska-rattigheter/johan-dimitri-taikon"
        ></link>
        <meta property="og:site_name" content="Demokrati Pågår"></meta>
        <meta property="og:type" content="article"></meta>
        <meta
          property="og:url"
          content="https://www.demokratipagar.se/historiska-perspektiv/utan-demokratiska-rattigheter/johan-dimitri-taikon"
        ></meta>
        <meta property="og:title" content="Johan Dimitri Taikon"></meta>
        <meta name="description" content="" />
        <script
          type="text/javascript"
          src="https://www.browsealoud.com/plus/scripts/3.1.0/ba.js"
          crossorigin="anonymous"
          integrity="sha256-VCrJcQdV3IbbIVjmUyF7DnCqBbWD1BcZ/1sda2KWeFc= sha384-k2OQFn+wNFrKjU9HiaHAcHlEvLbfsVfvOnpmKBGWVBrpmGaIleDNHnnCJO4z2Y2H sha512-gxDfysgvGhVPSHDTieJ/8AlcIEjFbF3MdUgZZL2M5GXXDdIXCcX0CpH7Dh6jsHLOLOjRzTFdXASWZtxO+eMgyQ=="
        ></script>
      </Helmet>
      <div className="c-body">
        <StudentHeader />
        <div role="main" id="main" className="o-main c-main-background">
          <ImageHero
            title="Johan Dimitri Taikon"
            img={imgJohanDimitri}
            alt="Porträtt av Johan Dimitri Taikon"
          >
            <p>
              Levde 1879-1950. Johan fick inte gå i skolan och kunde aldrig
              rösta i demokratiska val på grund av att han var rom. Johan ville
              förändra och kämpade för att romer skulle ha samma rättigheter som
              alla andra i Sverige.
              <br />
              <span className="c-description">
                Foto: Carl J Larsson, Länsmuseet Gävleborg
              </span>
            </p>
            <ul className="c-hero__questions">
              <li>
                <h3 className="c-hero__questions__title">
                  Huvudfråga: På vilket sätt saknade Johan Dimitri Taikon
                  demokratiska rättigheter?
                </h3>
              </li>
              <li>Lyssna på bildberättelsen och arbeta med källorna.</li>
            </ul>
          </ImageHero>

          <section
            className="c-content-section c-content-section--transparent"
            id="berattelse"
            aria-label="Berättelse"
          >
            <div className="container c-content-section__container">
              <div className="c-content-section__row row">
                <div className="col-xs-12">
                  <ButtonSign
                    to="https://vimeo.com/527303935"
                    text="Svenskt teckenspråk"
                  />
                  <VideoEmbed url="https://player.vimeo.com/video/519517566?api=1&player_id=vimeoplayer&title=0&byline=0&portrait=0&color=0BA126" />
                </div>
              </div>
            </div>
          </section>

          <section
            className="c-content-section"
            aria-labelledby="kalla-1-fotografi"
          >
            <SectionHeader id="kalla-1-fotografi" text="Källa 1 Fotografi" />
            <div className="container c-content-section__container">
              <div className="c-content-section__row row">
                <div className="col-xs-12">
                  <div className="c-content-section__content c-content-section__content--large">
                    <div className="c-source-block">
                      <div className="c-source-block__content">
                        <h3 className="c-source-block__content__title">
                          Fotografi
                        </h3>
                        <div className="c-source-block__source">
                          <div className="c-source-block__source__image">
                            <img
                              src={img1}
                              alt="Svartvitt foto med flera husliknande tält på en rad. Väggar och tak är av randiga tyger. Det sticker ut skorstenar från tälthusen. Utanför ett av tälten sitter en kvinna på en stol."
                            />
                          </div>
                          <div className="c-source-block__source__caption">
                            <p>
                              Fotografi av familjen Taikons läger i Johanneshov,
                              Stockholm, 1933 (foto: Gunnar Lundh, Nordiska
                              museet).
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="c-source-block__questions">
                        <h3 className="c-source-block__questions__title">
                          Frågor till källan
                        </h3>
                        <ul>
                          <li>Vad föreställer bilden?</li>
                          <li>Varför tror du någon har tagit den?</li>
                          <li>Vilka tankar och frågor väcker bilden?</li>
                          <li>Vad berättar den om Johan och hans liv?</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="c-content-section"
            aria-labelledby="kalla-2-fotografi"
          >
            <SectionHeader id="kalla-2-fotografi" text="Källa 2 Fotografi" />
            <div className="container c-content-section__container">
              <div className="c-content-section__row row">
                <div className="col-xs-12">
                  <div className="c-content-section__content c-content-section__content--large">
                    <div className="c-source-block">
                      <div className="c-source-block__content">
                        <h3 className="c-source-block__content__title">
                          Fotografi
                        </h3>
                        <div className="c-source-block__source">
                          <div className="c-source-block__source__image">
                            <img
                              src={img2}
                              alt="Svartvitt foto i ett kök. Till höger står en stor järnspis med kannor och kastruller på. Genom ett fönster tittar två barn in. De tittar rakt mot kameran."
                            />
                          </div>
                          <div className="c-source-block__source__caption">
                            <p>
                              Fotografi av familjen Taikons läger i Johanneshov,
                              Stockholm, 1933 (foto: Gunnar Lundh, Nordiska
                              museet).
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="c-source-block__questions">
                        <h3 className="c-source-block__questions__title">
                          Frågor till källan
                        </h3>
                        <ul>
                          <li>Vad föreställer bilden?</li>
                          <li>Varför tror du någon har tagit den?</li>
                          <li>Vilka tankar och frågor väcker bilden?</li>
                          <li>Vad berättar den om Johan och hans liv?</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            className="c-content-section"
            aria-labelledby="kalla-3-tidningsartikel"
          >
            <SectionHeader
              id="kalla-3-tidningsartikel"
              text="Källa 3 Tidningsartikel"
            />
            <div className="container c-content-section__container">
              <div className="c-content-section__row row">
                <div className="col-xs-12">
                  <div className="c-content-section__content c-content-section__content--large">
                    <div className="c-source-block">
                      <div className="c-source-block__content">
                        <h3 className="c-source-block__content__title">
                          Tidningsartikel
                        </h3>
                        <div className="c-source-block__source">
                          <div className="c-source-block__source__image">
                            <img
                              src={img3}
                              alt="Foto på en tidningsartikel från 1943 med rubriken Den första Zigenarskolan avslutad. I ingressen står det: På en upptrampad plats om ungefär 8 gånger 15 meter, omgiven av zigenarvagnar, tält och ihopspikade brädor, som tillsammans bildar en sluten vagnborg står 9 enkla pulpeter och framför dem en svart tavla. Det är zigenarskolan i Sköndal, som på lördagen hade sin första avslutning med initiativtagaren, redaktör Sundberg som hedersgäst."
                            />
                          </div>
                          <div className="c-source-block__source__caption">
                            <p>
                              Tidningsartikel i Stockholmstidningen, 29 augusti
                              1943. I tidningsartikeln förekommer ordet
                              &quot;zigenare&quot;. Ordet användes förr om
                              romer. Idag är det ett kränkande ord som inte ska
                              användas.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="c-source-block__text">
                        <ButtonPurple
                          text="Sammanfattning av artikeln"
                          to="stockholmstidningen-29-augusti-1943"
                        />
                      </div>
                      <div className="c-source-block__questions">
                        <h3 className="c-source-block__questions__title">
                          Frågor till källan
                        </h3>
                        <ul>
                          <li>Vad handlar tidningsartikeln om?</li>
                          <li>Vad föreställer bilderna till artikeln?</li>
                          <li>Varför tror du någon har skrivit artikeln?</li>
                          <li>Vilka tankar och frågor väcker artikeln?</li>
                          <li>
                            Vad berättar tidningsartikeln om romska barns
                            skolgång på 1940-talet?
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="c-image-teaser__action--wide">
            <ButtonOutline
              text="Länk och QR-kod till denna sida"
              title="Johan Dimitri Taikon"
              onClick={() => {
                handleShowOverlay(
                  "Utan demokratiska rättigheter: Johan Dimitri Taikon",
                  "Öppna med QR-kod eller skriv in länk:",
                  "bit.ly/johandimitri"
                )
              }}
            ></ButtonOutline>
          </div>
        </div>
        <Footer />
        {showOverlay && (
          <QROverlay
            show={showOverlay}
            heading={heading}
            text={text}
            linkText={linkText}
            toggleShow={handleShowOverlay}
          />
        )}
        {/*<StickyCta />*/}
      </div>
    </>
  )
}

export default JohanDimitriTaikon
