import React from "react"
import QRCode from "qrcode.react"
const close = require("../images/icons/dp-close.svg")

export default function QROverlay(props) {
  return (
    <div
      className={`c-qr-overlay ${
        props.show ? "c-qr-overlay--show" : "c-qr-overlay--hide"
      }`}
    >
      <button
        className="c-qr-overlay__close c-button c-button--close"
        onClick={props.toggleShow}
      >
        <span className="c-button__text">Stäng</span>
        <img className="c-button__img" src={close} />
      </button>
      <div className="c-qr-overlay__container container">
        <div className="c-qr-overlay__header">
          <h1 aria-label={props.heading + " - QR-kod och kort-länk"}>
            {props.heading}
          </h1>
        </div>
        <div className="row c-qr-overlay__content">
          <div className="col-xs-12 col-lg-6">
            <p className="c-qr-overlay__content__text">{props.text}</p>

            {props.linkText && (
              <p className="c-qr-overlay__content__link-text">
                {props.linkText}
              </p>
            )}
          </div>
          <div className="col-xs-12 col-lg-6 c-qr-overlay__content__img__container">
            <QRCode
              value={props.linkText}
              className="c-qr-overlay__content__img"
              renderAs="svg"
              width="100%"
              height="100%"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
