import React from "react"
import Link from "gatsby-link"
const eyeIcon = require("../images/icons/dp-icon-sign.svg")

export default function ButtonSign(props) {
  return (
    <Link
      to={props.to}
      className="c-button c-button--purple c-button--sign"
      activeClassName="-active"
      target="_blank"
    >
      <span className="c-button__text">{props.text}</span>
      <img className="c-button__img" src={eyeIcon} alt="" />
    </Link>
  )
}
