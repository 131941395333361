import React from "react"
import Link from "gatsby-link"

export default function ButtonOutline(props) {
  return (
    <>
      {props.onClick && (
        <button
          onClick={props.onClick}
          className="c-button c-button--outline"
          aria-label={props.ariaLabel}
        >
          <span className="c-button__text">{props.text}</span>
        </button>
      )}

      {props.to && (
        <Link
          to={props.to}
          className="c-button c-button--outline"
          activeClassName="-active"
        >
          <span className="c-button__text">{props.text}</span>
        </Link>
      )}
    </>
  )
}
