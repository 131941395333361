import * as React from "react"

const ImageHero = props => {
  return (
    <section
      className={
        props.modifier
          ? "c-image-hero c-image-hero--" + props.modifier
          : "c-image-hero"
      }
    >
      <div className="container">
        <div className="c-image-hero__content">
          {props.img && (
            <div className="c-image-hero__image">
              <div className="c-image-hero__image__mask">
                <img src={props.img} alt={props.alt} />
              </div>
            </div>
          )}
          <div>
            <h1 className="c-image-hero__title">{props.title}</h1>
            <div className="c-image-hero__preamble">{props.children}</div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ImageHero
